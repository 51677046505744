/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import { Grid, Box, AspectRatio } from 'theme-ui';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import Heading from '../Heading';
import Link from '../Link';

const ProjectTeaser = ({
  title,
  subtitle,
  variant,
  children,
  mainImage,
  featuredImage,
  slug,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    // triggerOnce: true,
  });

  return (
    <motion.div animate={inView ? 'show' : 'hide'}>
      <Grid
        ref={ref}
        columns={['repeat(4, 1fr)', null, 'repeat(12, 1fr)']}
        gap={4}
        sx={{
          maxWidth: 240,
          px: [3, null, 14],
          mx: 'auto',
        }}
      >
        <Grid
          columns={['repeat(3, 1fr)', null, 'repeat(10, 1fr)']}
          row="min-content 1fr 1fr"
          gap={[2, 4]}
          sx={{
            mb: [8, 16],
            width: '100%',
            gridColumn: ['1/ -1', null, '2/ span 10'],
          }}
        >
          <Box
            sx={{
              display: ['none', null, 'block'],
              gridColumn: variant === 'titleAbove' ? '3/span 8' : '1/ span 8',
              gridRow: variant === 'titleAbove' ? '2/span 2' : '1/span 2',
            }}
          >
            <AspectRatio ratio={1800 / 1000}>
              <motion.div
                variants={{
                  show: { y: 0, opacity: 1 },
                  hide: { y: 130, opacity: 0 },
                }}
              >
                <Link to={slug}>
                  <Img fluid={featuredImage} />
                </Link>
              </motion.div>
            </AspectRatio>
          </Box>
          <Box
            sx={{
              gridColumn: [
                '1/ -1',
                null,
                variant === 'titleAbove' ? '1/span 4' : '7/ span 4',
              ],
              gridRow: [
                '1/ span 1',
                null,
                variant === 'titleAbove' ? '1/span 2' : '2/span 2',
              ],
            }}
          >
            <AspectRatio ratio={1 / 1}>
              <motion.div
                variants={{
                  show: { y: 0, opacity: 1 },
                  hide: { y: 150, opacity: 0 },
                }}
              >
                <Link to={slug}>
                  <Img fluid={mainImage} />
                </Link>
                {/* <img
                  sx={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                  src={mainImage}
                /> */}
              </motion.div>
            </AspectRatio>
          </Box>

          <Box
            sx={{
              gridColumn: [
                '1/-1',
                null,
                variant === 'titleAbove' ? '6/ span 4' : '2/span 4',
              ],
              gridRow: [
                '2/span 1',
                null,
                variant === 'titleAbove' ? '1/ span 1' : '3/ span 1',
              ],
              mb: [0, null, 11],
            }}
          >
            <Heading variant="h2" as="h2" color="blue">
              <Link to={slug}>{title}</Link>
            </Heading>
            <Heading variant="subtitle" color="pig">
              <Link to={slug}>{subtitle}</Link>
            </Heading>
          </Box>
        </Grid>
      </Grid>
    </motion.div>
    // <Grid gap={3} mx={[3, 14]} columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']}>
    //     <Box sx={{
    //         gridColumn:['1/-1','7/12'],
    //         gridRow: ['auto', variant === "titleAbove"? 1: 2]
    //     }}>
    //         <Heading variant={'h2'} as={'h2'} color="blue" >
    //             {title}
    //         </Heading>
    //         <Heading variant={"subtitle"} color="pig">
    //             {subtitle}
    //         </Heading>
    //     </Box>
    //     <Box sx={{
    //         gridColumn: ['1/-1','2/6'],
    //         gridRow: ['auto', variant === "titleAbove"? 2: 1]
    //     }}>
    //         {children}
    //     </Box>
    // </Grid>
  );
};

export default ProjectTeaser;
