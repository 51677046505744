import React from 'react';
import { Box } from 'theme-ui';
import Heading from '../Heading';

const Teaser = ({
  sx,
  subtitle,
  children,
  backgroundImage,
  bgcolor,
  image,
}) => (
  <Box
    sx={{
      ...sx,
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: bgcolor,
      backgroundSize: ['contain'],
      backgroundPosition: 'top right',
      width: '100%',
      px: [3, null, 9],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    {image}
    <Box>
      {subtitle ? (
        <Heading
          variant="subtitle"
          color="white"
          sx={{ fontWeight: 'medium' }}
        >
          {subtitle}
        </Heading>
      ) : null}
      <Heading color="white" as="h3">
        {children}
      </Heading>
    </Box>
  </Box>
);

export default Teaser;
