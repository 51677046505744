import React from 'react';
import { Box, Grid, Flex } from 'theme-ui';
import Schlingeling from '../Schlingeling';
import Heading from '../Heading';
import Header from '../Header';
import Link from '../Link';
import Teaser from '../Teaser';
import { ReactComponent as Berlin } from '../../assets/images/berlin.svg';

import { ReactComponent as Stamp } from '../../assets/images/stamp.svg';

const CardHero = () => (
  <Box sx={{ position: 'relative' }}>
    <Box sx={{ position: 'absolute', zIndex: '1', minWidth: '100vw' }}>
      <Header showNav={false} />
    </Box>
    <Grid
      columns={[
        1,
        null,
        'minmax(0em, 1fr) repeat(2, minmax(0, 720px)) minmax(0em, 1fr)',
      ]}
      rows={[1, null, '1fr 1fr']}
      gap={0}
      sx={{
        height: ['auto'],
        minHeight: [0, null, '100vh'],
      }}
    >
      <Box
        sx={{
          gridRow: '1/ span 2',
          overflow: 'hidden',
          backgroundColor: 'basementPig',
          display: ['none', null, 'block'],
        }}
      />
      <Box
        sx={{
          gridRow: ['auto', null, '1/span 2'],
          gridColumn: ['auto', null, '2/ span 1'],
          backgroundColor: 'basementPig',
          pt: [18],
          pb: [6],
        }}
      >
        <Grid
          columns={['repeat(4, 1fr)', null, 'repeat(6, 1fr)']}
          gap={[3, null, 4]}
          sx={{
            gridTemplateRows: 'min-content auto',
            height: '100%',
            mx: [3, null, 0],
          }}
        >
          <Flex
            sx={{
              gridColumn: ['1/ span 4', null, '2/ span 4'],
              gridRow: '2/ span 1',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Heading
              variant="subtitle"
              color="pig"
              sx={{ fontWeight: 'medium' }}
            >
              Oh, Hi There
            </Heading>

            <Heading variant="h1" as="h2" color="blue">
              I’m Sabrina, a Product Designer & Lecturer based in Cape Town,
              South Africa. I use my curiosity to explore and design digital
              experiences for businesses.
            </Heading>

            <Schlingeling />

            <Heading
              variant="subtitle"
              color="orange"
              sx={{ fontWeight: 'medium' }}
            >
              Scroll to see more work
            </Heading>
          </Flex>
        </Grid>
      </Box>
      <Link
        to="/about"
        sx={{
          display: 'flex',
          gridRow: ['auto', null, '1/span 1'],
          gridColumn: ['auto', null, '3/ span 1'],
        }}
      >
        <Teaser
          bgcolor="pig"
          subtitle="About"
          sx={{}}
          image={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
                mb: [5, null, 0],
                flexBasis: '100%',
                flexGrow: 1,
              }}
            >
              <Berlin style={{ width: '169px', height: '169px' }} />
            </Box>
          }
        >
          What I did, do and where things are going.
        </Teaser>
      </Link>
      <Link
        to="mailto:sabrina@somewhere-south.com"
        sx={{
          display: 'flex',
          gridRow: ['auto', null, '2/span 1'],
          gridColumn: ['auto', null, '3/ span 1'],
        }}
      >
        <Teaser
          // backgroundImage={stamp}
          bgcolor="blue"
          subtitle="Get in touch"
          sx={{ width: '100%', backgroundSize: 'cover' }}
          image={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                ml: -9,
              }}
            >
              <Stamp
                style={{
                  width: '284px',
                  height: '245px',
                }}
              />
            </Box>
          }
        >
          Looking to collaborate?
          <br />
          Drop me a line.
        </Teaser>
      </Link>
      <Box
        sx={{
          gridRow: '1/span 1',
          gridColumn: '4/ span 1',
          overflow: 'hidden',
          backgroundColor: 'pig',
          display: ['none', null, 'block'],
        }}
      />
      <Box
        sx={{
          gridRow: '2/span 1',
          gridColumn: '4/ span 1',
          overflow: 'hidden',
          backgroundColor: 'blue',
          display: ['none', null, 'block'],
        }}
      />
    </Grid>
  </Box>
);

export default CardHero;
