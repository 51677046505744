/** @jsx jsx */
import { AspectRatio, Box, jsx } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CardHero from '../components/CardHero';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ProjectTeaser from '../components/ProjectTeaser';
import Layout from '../global/Layout';

const IndexPage = ({ data }) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <Layout>
      <div ref={ref} sx={{ mb: [10] }}>
        <CardHero />
      </div>
      <Box sx={{ position: 'fixed', zIndex: 1000, minWidth: '100vw' }}>
        <motion.div
          animate={!inView ? 'show' : 'hide'}
          variants={{
            show: {
              opacity: 1,
              y: 0,
            },
            hide: {
              opacity: 0,
              y: '-200px',
            },
          }}
        >
          <Box
            sx={{
              background: 'rgba(255,255,255,0.8)',
              pb: 6,

              backdropFilter: 'blur(10px)',
            }}
          >
            <Header />
          </Box>
        </motion.div>
      </Box>
      <Box sx={{ mt: 0 }}>
        {data.allSanityProject.edges.map(({ node }, index) => (
          <ProjectTeaser
            title={node.title}
            subtitle={node.category}
            variant={index % 2 === 0 ? 'titleAbove' : 'titleBelow'}
            mainImage={node.mainImage.asset.fluid}
            featuredImage={node.featuredImage.asset.fluid}
            slug={node?.slug?.current}
          />
        ))}
      </Box>

      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query Projects {
    allSanityProject(sort: { order: ASC, fields: endedAt }) {
      edges {
        node {
          title
          category
          slug {
            current
          }
          featuredImage {
            asset {
              fluid(maxWidth: 1800) {
                ...GatsbySanityImageFluid
              }
            }
          }
          mainImage {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
